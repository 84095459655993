<template>
    <b-modal id="send-image-modal" title="Kirim Gambar" @show="resetModal">
        <div class="">
            <form class="form-horizontal form-material">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 p-0" style="margin-right:0">
                            <div class="list-img-upload">
                                
                                <div class="card card-img" v-for="(e, index) in urlImage" :key="index">
                                    <img class="images-preview-modal img-responsive" v-bind:src="e">
                                    <div class="wrap-action-img">
                                                                            &nbsp;
                                    <a class="delete-link"
                                        @click="urlImage.splice(index, 1); filesUpload.splice(index, 1);"><i
                                            class="fa fa-close" title="Hapus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    <div class="col-md-12">
                        <input type="file" id="file" ref="file" v-on:change="fileImages" accept=".jpg,.png,.jpeg,.webp"
                            multiple />
                    </div>
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Caption" v-model="caption">
                            <div class="form-control-feedback text-danger">
                            </div>
                        </div>
                    </div>
                    <!--div class="col-md-12 m-b-20">
                        <input type="textarea" class="form-control" placeholder="Deskripsi" v-model="messageText">
                        <div class="form-control-feedback text-danger">
                        </div>
                    </div-->
                    
                </div>
            </form>
        </div>
        <div v-if="(filesUpload.length > 1)">
            <span>Image Uploaded : {{ uploadedCount }}/{{ filesUpload.length }}</span>
        </div>
        <b-progress v-if="isSendInProgress" :value="uploadPercentage" show-progress animated></b-progress>
        <ErrorMessage v-bind:error="error" />
        <template v-slot:modal-footer>
            <button type="button" class="btn btn-default waves-effect" :disabled="isSending"
                @click="$bvModal.hide('send-image-modal')">Batal</button>
            <button type="button" class="btn btn-default waves-effect" id="btn-send-image" @click="uploadImages" :disabled="isSending">
                <i v-if="isSendInProgress" class="fa fa-circle-o-notch fa-spin "></i> &nbsp;Upload ke media</button>
            <button type="button" class="btn btn-primary waves-effect" id="btn-send-image" @click="sendImages" :disabled="isSending">
                <i v-if="isSendInProgress" class="fa fa-circle-o-notch fa-spin"></i> &nbsp;Kirim</button>
        </template>
    </b-modal>
</template>

<script>
    import ErrorMessage from '../components/ErrorMessage'
    import axios from 'axios'
    const uuidv4 = require('uuid/v4')

    export default {
        data: function () {
            return {
                file: null,
                filesUpload: [],
                urlImage: [],
                caption: '',
                messageText: '',
                error: '',
                isSendInProgress: false,
                uploadPercentage: 0,
                uploadedCount: 0,
                isSending: false,
            }
        },
        methods: {
            resetModal: function () {
                this.file = null;
                this.caption = '';
                this.urlImage = [];
                this.messageText = '';
                this.error = {};
                this.isSendInProgress = false;
                this.uploadPercentage = 0;
                this.filesUpload = [];
                this.uploadedCount = 0;
            },
            fileImages: function () {
                const imagesFiles = this.$refs.file.files

                for (const property in imagesFiles) {
                    const file = imagesFiles[property]

                    if(typeof file == 'object') {
                        this.handleFileUpload(file)
                    }
                }

            },
            uploadImages: async function () {
                this.isSending = true
                for (let index = 0; index < this.filesUpload.length; index++) {
                    await this.uploadFile(this.filesUpload[index])
                    this.uploadedCount += 1;
                }

                this.isSending = false
                this.$bvModal.hide('send-image-modal');
                this.resetModal()
                this.$emit('clearQuoted')
            },
            sendImages: async function () {
                this.isSending = true

                for (let index = 0; index < this.filesUpload.length; index++) {
                    await this.submitFile(this.filesUpload[index])
                    this.uploadedCount += 1;
                }

                this.isSending = false
                this.$bvModal.hide('send-image-modal');
                this.resetModal()
                this.$emit('clearQuoted')
            },
            handleFileUpload: function (file) {
                this.error = {}

                if (file.size > 16000000) {
                    this.error = this.$helpers.createError({
                        message: "Max file size 10mb"
                    })
                    document.getElementById("file").value = null;
                    return
                }

                var reader = new FileReader();

                var app = this;

                if (file) {
                    reader.onloadend = function () {
                        app.urlImage = [...app.urlImage, reader.result]
                        app.filesUpload = [...app.filesUpload, file]
                    }

                    reader.readAsDataURL(file);
                } else {
                    this.urlImage = [];
                }
                
                this.error = {};
            },
            submitFile: async function (eFile) {
                var appSubmit = this;
                this.error = {}

                if (!this.$helpers.isBlank(eFile)) {
                    this.isSendInProgress = true;
                    this.uploadPercentage = 0;
                    let formData = new FormData();
                    formData.append('file', eFile);
                    formData.append('caption', this.caption);
                    //formData.append('description', this.messageText);
                    formData.append('id', uuidv4());
                    formData.append('channel', this.channel);
                    formData.append('channelId', this.sourceChannelId);
                    formData.append('number', this.destNumber);
                    // if (this.channel == 'FB') {
                    //     formData.append('number', this.destNumber);
                    // } else if (this.channel == 'SMS' || this.channel == 'WA') {
                    //     formData.append('number', this.destNumber);
                    // }

                    if (this.qmOriginId) {
                        formData.append('qmOriginId', this.qmOriginId)
                        formData.append('qmText', this.qmText)
                        formData.append('qmOriginExternalId', this.qmOriginExternalId)
                        formData.append('qmAuthorId', this.qmAuthorId)
                        formData.append('qmAuthorName', this.qmAuthorName)
                    }

                    try {
                        await axios.post(process.env.VUE_APP_API_URL + '/message/' + this.contactId + '/' + this
                            .destNumber + '/image',
                            formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                onUploadProgress: function (progressEvent) {
                                    appSubmit.uploadPercentage = parseInt(Math.round((progressEvent.loaded /
                                        progressEvent.total) * 100))
                                }
                            }
                        )

                        this.$emit('send-image-callback-api', null)

                    } catch (error) {
                        this.$emit('send-image-callback-api', error)
                        this.error = this.$helpers.createError(error)
                    }

                    appSubmit.isSendInProgress = false;
                } else {
                    this.error = {
                        html: "Gambar masih kosong"
                    }
                }
            },
            uploadFile: async function (eFile) {
                var appSubmit = this;
                this.error = {}

                if (!this.$helpers.isBlank(eFile)) {
                    this.isSendInProgress = true;
                    this.uploadPercentage = 0;
                    let formData = new FormData();
                    formData.append('file', eFile);
                    formData.append('caption', this.caption);
                    formData.append('mediaType', 'image');

                    try {
                        await axios.post(process.env.VUE_APP_API_URL + '/attachment',
                            formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                onUploadProgress: function (progressEvent) {
                                    appSubmit.uploadPercentage = parseInt(Math.round((progressEvent.loaded /
                                        progressEvent.total) * 100))
                                }
                            }
                        )

                    } catch (error) {
                        this.error = this.$helpers.createError(error)
                    }

                    appSubmit.isSendInProgress = false;
                } else {
                    this.error = {
                        html: "Gambar masih kosong"
                    }
                }
            }
        },
        props: [
            'contactId', 'destNumber', 'sourceChannelId', 'channel',
            'qmOriginId', 'qmText', 'qmOriginExternalId', 'qmAuthorId', 
            'qmAuthorName'
        ],
        components: {
            ErrorMessage
        }
    }
</script>